import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import * as THREE from 'three'
import { TweenMax, Power4 } from 'gsap/TweenMax'
import PageConsumer from '@/hoc/PageConsumer'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { getThemeColor } from '@/utils/themes'

class ParticleImage extends React.Component {
  constructor (props) {
    super(props)
    this.rootRef = React.createRef()
    this.wrapperRef = React.createRef()
    this.canvasRef = React.createRef()
    this.rafHandle = null
    this.updateTimer = null
    this.imagedata = null
    this.texture = null
    this.renderer = null
    this.camera = null
    this.scene = null
    this.particles = null
    this.loop1Completed = false
    this.needsUpdate = false
    this.imageSize = {
      width: 0,
      height: 0
    }
    this.centerVector = new THREE.Vector3(0, 0, 0)
    this.previousTime = 0
    this.initialized = false
    this.id = Math.random()
    this.state = {
      width: 0,
      height: 0
    }
  }
  componentDidMount () {
    this.props.page.addStartHandlers(this.init)
    this._isMounted = true
  }
  componentWillUnmount () {
    this._isMounted = false
    this.stopLoop()
    this.stopUpdateTimer()
  }
  componentDidUpdate (prevProps) {
    if (prevProps.windowInitialized !== this.props.windowInitialized) return
    if (prevProps.windowWidth !== this.props.windowWidth) {
      this.setUpdateTimer()
    } else if (prevProps.color !== this.props.color ||
      prevProps.type !== this.props.type ||
      prevProps.scale !== this.props.scale ||
      prevProps.src !== this.props.src) {
      this.setUpdateTimer()
    }
  }
  init = () => {
    setTimeout(async () => {
      if (this.initialized) return
      if (!this._isMounted) return
      await this.loadImage()
      if (this.initialized) return
      if (!this._isMounted) return
      this.initialized = true
      await this.updateSize(this.texture.image)
      if (!this._isMounted) return
      this.updateImagedata()
      this.initRenderer()
      this.initScene()
      this.initCamera()
      this.initParticles()
      this.startLoop1()
    }, 300)
  }
  async update () {
    this.stopLoop()
    await this.loadImage()
    if (!this._isMounted) return
    await this.updateSize(this.texture.image)
    if (!this._isMounted) return
    this.updateImagedata()
    this.updateRenderer()
    this.updateCamera()
    this.initParticles()
    this.startLoop2()
  }
  async updateSize (image) {
    let width, height
    if (this.props.fixedHeight) {
      const ratio = image.width / image.height
      height = this.wrapperRef.current.clientHeight * 1.5
      width = height * ratio
    } else if (this.props.fixedWidth) {
      const ratio = image.height / image.width
      width = this.wrapperRef.current.clientWidth * 1.5
      height = width * ratio
    } else {
      width = Math.min(this.wrapperRef.current.clientWidth * 1.5, window.innerWidth)
      height = this.wrapperRef.current.clientHeight * 1.5
    }
    await this.setState({
      width, height
    })
  }
  async loadImage () {
    return new Promise((resolve) => {
      const loader = new THREE.TextureLoader()
      this.texture = loader.load(this.props.src, () => {
        resolve()
      })
    })
  }
  updateImagedata () {
    this.imagedata = this.getImageData(this.texture.image)
  }
  initRenderer () {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvasRef.current,
      antialias: true,
      alpha: true
    })
    this.renderer.setSize(this.state.width, this.state.height)
    this.renderer.setClearColor(0x000000, 0)
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1.5))
  }
  updateRenderer () {
    this.renderer.setSize(this.state.width, this.state.height)
  }
  initScene () {
    this.scene = new THREE.Scene()
  }
  initCamera () {
    this.camera = new THREE.PerspectiveCamera(50, this.state.width / this.state.height, 0.1, 10000)
    const cameraZ = -(this.state.height / 2) / Math.tan((this.camera.fov * Math.PI / 180) / 2)
    if (this.props.animate) this.camera.position.set(0, 0, -cameraZ)
    else this.camera.position.set(0, 0, -cameraZ)
    this.camera.lookAt(this.centerVector)
    this.camera.aspect = this.state.width / this.state.height
    this.camera.updateProjectionMatrix()
    this.scene.add(this.camera)
  }
  updateCamera () {
    const cameraZ = -(this.state.height / 2) / Math.tan((this.camera.fov * Math.PI / 180) / 2)
    if (this.props.animate) this.camera.position.set(0, 0, -cameraZ)
    else this.camera.position.set(0, 0, -cameraZ)
    this.camera.lookAt(new THREE.Vector3(0, 0, 0))
    this.camera.aspect = this.state.width / this.state.height
    this.camera.updateProjectionMatrix()
  }
  initParticles () {
    if (this.particles) this.scene.remove(this.particles)
    const geometry = new THREE.Geometry()
    const material = new THREE.PointsMaterial({
      size: 1,
      color: this.getColor(),
      sizeAttenuation: false,
      transparent: true
    })
    for (let y = 0, y2 = this.imagedata.height; y < y2; y += 2) {
      for (let x = 0, x2 = this.imagedata.width; x < x2; x += 2) {
        if (this.imagedata.data[(x * 4 + y * 4 * this.imagedata.width) + 3] <= 128) {
          continue
        }

        const vertex = new THREE.Vector3()
        if (this.loop1Completed) {
          vertex.x = x - this.imageSize.width / 2
          vertex.y = -y + this.imageSize.height / 2
          vertex.z = 0
        } else {
          vertex.x = Math.random() * this.imageSize.width * 2 - this.imageSize.width
          vertex.y = Math.random() * this.imageSize.height * 2 - this.imageSize.height
          vertex.z = 0
        }

        vertex.destination = {
          x: x - this.imageSize.width / 2,
          y: -y + this.imageSize.height / 2,
          z: 0
        }

        vertex.speed = 0.045

        geometry.vertices.push(vertex)
      }
    }
    this.particles = new THREE.Points(geometry, material)
    this.scene.add(this.particles)
  }
  getImageData (image) {
    const imageCanvas = document.createElement('canvas')
    imageCanvas.width = this.state.width
    imageCanvas.height = this.state.height

    const ratioX = this.wrapperRef.current.clientWidth / image.width
    const ratioY = this.wrapperRef.current.clientHeight / image.height
    const ratio = Math.min(ratioX, ratioY)

    const sw = image.width
    const sh = image.height
    const dw = image.width * ratio
    const dh = image.height * ratio
    this.imageSize.width = ratio >= 1 ? Math.max(sw, dw) : Math.min(sw, dw)
    this.imageSize.height = ratio >= 1 ? Math.max(sh, dh) : Math.min(sh, dh)

    const ctx = imageCanvas.getContext('2d')
    ctx.drawImage(image,
      0, 0, sw, sh,
      0, 0, dw, dh)

    return ctx.getImageData(0, 0, this.imageSize.width, this.imageSize.height)
  }
  getColor () {
    if (this.props.type === 'contrast') {
      return this.props.theme.text.contrast
    } else if (this.props.scale) {
      return this.props.theme.scale[this.props.scale]
    } else if (this.props.color) {
      return getThemeColor(this.props.color, this.props.theme)
    } else {
      return this.props.theme.text.default
    }
  }
  startLoop1 () {
    this.rafHandle = window.requestAnimationFrame(this.loop1.bind(this))
    let count = 0
    for (let i = 0, j = this.particles.geometry.vertices.length; i < j; i++) {
      const particle = this.particles.geometry.vertices[i]
      TweenMax.to(particle, Math.random() + 1, {
        x: particle.destination.x,
        y: particle.destination.y,
        ease: Power4.easeOut,
        onComplete: () => {
          count += 1
          if (count >= this.particles.geometry.vertices.length) {
            this.loop1Completed = true
            if (this.needsUpdate) {
              this.update()
            } else {
              this.startLoop2()
            }
          }
        }
      })
    }
    TweenMax.from(this.particles.material, 5, {
      opacity: 0,
      ease: Power4.easeOut
    })
  }
  startLoop2 () {
    this.rafHandle = window.requestAnimationFrame(this.loop2.bind(this))
  }
  stopLoop () {
    window.cancelAnimationFrame(this.rafHandle)
  }
  setUpdateTimer () {
    if (!this.loop1Completed) {
      this.needsUpdate = true
      return
    }
    clearTimeout(this.updateTimer)
    this.updateTimer = setTimeout(() => {
      if (!this._isMounted) return
      this.update()
    }, 300)
  }
  stopUpdateTimer () {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer)
    }
  }
  loop1 (a) {
    if (this.props.page.status === 0) {
      return
    }
    if (this.loop1Completed) {
      return
    }
    this.rafHandle = window.requestAnimationFrame(this.loop1.bind(this))
    this.particles.geometry.verticesNeedUpdate = true
    this.particles.material.needsUpdate = true
    if (this.props.animate) {
      this.camera.position.x = Math.sin(a / 3000) * (100 * (this.state.height / 1000))
      this.camera.position.y = Math.sin(a / 1000) * (this.state.height * 0.1)
      this.camera.position.z += Math.sin(a / 2000) * (this.state.height * 0.0005)
      this.camera.lookAt(this.centerVector)
    }
    this.renderer.render(this.scene, this.camera)
  }
  loop2 (a) {
    if (this.props.page.status === 0) {
      return
    }
    this.rafHandle = window.requestAnimationFrame(this.loop2.bind(this))
    if (this.props.animate) {
      this.camera.position.x = Math.sin(a / 3000) * (100 * (this.state.height / 1000))
      this.camera.position.y = Math.sin(a / 1000) * (this.state.height * 0.1)
      this.camera.position.z += Math.sin(a / 2000) * (this.state.height * 0.0005)
    }
    this.camera.lookAt(this.centerVector)
    this.renderer.render(this.scene, this.camera)
  }
  render () {
    const { className, style } = this.props
    return (
      <Root className={className} style={style} ref={this.rootRef}>
        <Wrapper ref={this.wrapperRef}>
          <Canvas ref={this.canvasRef} width={this.state.width}></Canvas>
        </Wrapper>
      </Root>
    )
  }
}

ParticleImage.defaultProps = {
  animate: true,
  initDelay: 600
}

ParticleImage.propTypes = {
  animate: PropTypes.bool,
  initDelay: PropTypes.number
}

const Root = styled.div`
  pointer-events: none;
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
`

const Canvas = styled.canvas`
  display: block;
  position: absolute;
  top: 50%;
  left: calc((100% - ${props => props.width}px) / 2);
  transform: translateY(-50%);
`

const mapStateToProps = state => {
  return {
    windowInitialized: state.UI.Window.initialized,
    windowWidth: state.UI.Window.width
  }
}

export default PageConsumer(ThemeConsumer(connect(mapStateToProps)(ParticleImage)))
